.view-product-image{
    object-fit: contain;
    align-self: center;
}
.product-attribute-container{
    margin-bottom: 20px;
}
.product-attribute-label{
    font-size: 16px;
    font-weight: bold;
}
.product-attribute-value{
    font-size: 16px;
}
.product-description{
    white-space: pre-line;
}
