#top-categories-mobile-scroll{
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */
    overflow-y: scroll;
}
#top-categories-mobile-scroll::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
}
#account-option-title{
    font-size: 18px;
}
#account-chevron-icon{
    margin-right: 20px;
}
#account-option-container{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
#landing-account-button:focus{
    box-shadow: none;
}
#landing-phone-button:focus{
    box-shadow: none;
}
#landing-about-us-button:focus{
    box-shadow: none;
}
#lading-menu-button:focus {
    box-shadow: none;
}
.category-items-container{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.category-chevron-icon{
    margin-right: 20px;
}
.product-image{
    object-fit: contain;
    padding: 20px;
    align-self: center;
    width: 300px;
    height: 200px;
}
#landing-page-product-image{
    object-fit: contain;
    padding: 20px;
    align-self: center;
}
.landing-navbar{
    background-color: #AC0A0A
}
.bottom-navbar-text{
    color: #fff;
    font-size: 18px;
}
.bottom-navbar {
    background-color: #AC0A0A;
}
.landing-image{
    /*object-fit: contain;*/
}