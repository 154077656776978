@import "Landing.css";
@import "Product.css";
@import "Login.css";
@import "Profile.css";
@import "PhoneNumber.css";

.center-container{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}
.searchbar-container{
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    margin-bottom: 2rem;
}
.searchbar{
    margin-top: 2rem;
}
.logo{
    object-fit: contain;
}
.spinner{
    position: absolute;
    left: 45%;
    top: 50%;
}
.rotated-img{
    -webkit-transform:rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg);
}
.hover-button{
    position: fixed; /* Fixed/sticky position */
    bottom: 20px; /* Place the button at the bottom of the page */
    right: 15px; /* Place the button 30px from the right */
    z-index: 99; /* Make sure it does not overlap */
}
